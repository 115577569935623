.play-wrap >  div:not(.context-menu) {
  background: none;
}

.play-wrap {
  position: relative;
  z-index: 1;
  height: 100%;
  box-shadow: 0px 0px 15px 4px rgba(0,100,0,.2);
}
.quality-text{
  color:black;
  font-weight: 800;
  font-size: x-large;
  position:absolute;
  top: 50%;
  z-index: 2;
  left: 50%;
  transform: translate(-50%, -50%);
}
.context-menu {
  display: flex;
  flex-direction: column;
  z-index: 1;
  position: absolute;
  font-size: small;
  height: 100px;
  min-width: 200px;
  background-color: blue;
  color: white;
  border-radius: 10px;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.context-menu {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  z-index: 1;
  position: absolute;
  font-size: small;
  height: 0;
  width: 0;
  overflow:hidden;
  background-color: #fff76a;
  color: #5f9b55;
  border-radius: 10px 10px 10px 10px;
  user-select: none;
}
.context-menu.show {
  padding: 20px;
  overflow:visible;
  height: 100px;
  width: 400px;
  transition: all 1.2s ease-in-out;
}
.context-menu:before{
  opacity: 0;
  content:'';
  display:block;
  width:0;
  position:absolute;
  border-left: 0px solid transparent;
  border-right: 0px solid transparent; 
  border-bottom: 0px solid #fff76a; 
  left: 180px;
  top:0px;
}
.context-menu.show:before{
  border-left: 40px solid transparent;
  border-right: 40px solid transparent;
  border-bottom: 40px solid #fff76a; 
  top:-40px;
  opacity: 1;
  transition: opacity 1s ease-in .5s;
}

.context-menu *{
  opacity: 0;
  width: 0;
  height: 0;
  overflow:hidden;
}
.context-menu.show *{
  opacity: 1;
  width: auto;
  height: auto;
  transition: opacity 1.5s ease-in;
}
.context-header {
  justify-content: space-between;
  display: flex;
  flex-direction: row;
}
.context-id {
  font-size:x-large;
  font-weight: 800;
}
.context-button { 
  font-weight: 600;
  background-color: #5f9b55;
  color: #fff76a;
  padding: 5px 8px;
  border-radius: 5px;
  cursor: pointer;
}
.context-button:hover { 
  filter: brightness(1.15);
}
.context-owner {
  display: flex;
  font-size: large;
  flex-direction: column;
}
.buttons {
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: 1;
}
.context-owner-title {
  font-weight: 800;
}
.context-owner-id {
  font-weight: 600;
}

.buttons svg:hover {
  color:white;
}
