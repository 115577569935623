.viewer {
  margin: 1rem 20vw;
  height: 80vh;
}
.name {
  border: 4px double black;
}
.game {
  position: relative;
  height: 100%;
  box-shadow: 0px 0px 15px 4px rgba(0,100,0,.2);
}
.stats {
  border: 4px double black;
}
.single-view-player {
  width: 100%;
  height: 100%;
}

.spinner-wrap {
  margin: auto;
}