.nav-bar {
  display: flex;
  width: 100%;
  height: 80px;
  background-color: #7abb61;
  z-index: 101;
}
.coll-bg { 
  background-color: #7abb61;
} 
.home { 
  background-color: #7abb61;
} 
a {
  text-decoration: none;
  color: black;
}

.nav-bar a {
  color: white;
  padding: 10px 30px;
  font-weight: 700;
  font-size: 22px;
  transition: all .4s ease-in-out;
  filter: blur(0px);
  margin: 20px;
}
.nav-bar a:not(.selected) {
  transform: rotate(0) !important;
}
.nav-bar a.selected {
  color: #7abb61;
  transform: rotate(4deg);
  border-radius: 30px;
  background-color: #fff76a;
}
.nav-bar a:hover:not(.selected) {
  transition: all .1s ease-in;
  color: #fff76a;
  transform: rotate(0) scale(1.2) !important;
}
.nav-bar a:active {
  transition: all .1s ease-in;
}
.mobile-nav-bar {
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: #5f9b55;
}
.open {

}
.close {
  position: absolute;
  top: 0px;
  right: 0px;
}
.menu-item:hover {
 background-color: #7abb61;
}
.menu-item a{
  padding: 30px 0px;
  width: 100%;
  display: block;
  font-size: xx-large;
}
.menu-item {
  border-bottom: 1px solid #446138;
}
.bar {
  display: flex; 
  justify-content: flex-end;
}
.nav-menu.show {
  display: initial;
  transition: transform 0.3s ease-in-out;
  transform: translateX(0);
}
.nav-menu {
  display: none;
  transform: translateX(100%);
  position: absolute;
  z-index: 100;
  background-color: #5f9b55;
  width: 100vw;
  height: 100vh;
}


