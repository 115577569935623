.chat-wrap {
  width: 600px;
}
.messages {
  border: 2px solid black;
  height: 400px;
  overflow-y: scroll;
}
.input-box {
  width: 100%;
}
.messages > div:nth-of-type(odd) {
  background: rgb(221, 221, 221);
}
.input-box >input {
  box-sizing:border-box;
  width: 100%;
  line-height: 40px;
}