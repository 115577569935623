.stats-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.stats {
  background-color: green;
  border-radius: 30px;
}
.view-button {
  user-select: none;
  font-weight: 800;
  font-size: x-large;
  background-color: #fff76a;
  color: #7abb61;
  border-radius: 20px;
  padding: 10px 20px;
  margin: -50px auto 50px;
  z-index: 1;
}

.play-bomber {
  display: inline-block;
  margin: auto;
  user-select: none;
  font-weight: 800;
  font-size: x-large;
  background-color: #fff76a;
  color: #7abb61;
  border-radius: 20px;
  padding: 10px 20px;
  text-align: center;
  z-index: 1;
}
.play-bomber:hover {
  transform: scale(1.1,1.1); 
}
.view-button:hover {
  transform: scale(1.05,1.05);

}
.games-list {
  border-radius: 30px;
  padding: 30px 20px;
  background-color: #5f9b55;
}
.games-list-title {
  color: white;
  margin: 0px 10px 20px 10px;
  font-size: x-large;
  font-weight: 700;
}
.stats-card-wrap {
  display: flex;
  flex-direction: column;
}