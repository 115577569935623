.collection {
    width: 80%;
    margin: 10px 5%;
    display: grid;
    grid-template-columns: repeat(auto-fit,  minmax(10px, max-content)) ;
}
.collection > div:hover {
  z-index: 50;
  transform: scale(1.05) rotate(-3deg);;
}
.collection > div {
  transition: all .2s ease-in-out;
}
.garden-background {
  background-color: #7abb61;
}
.garden-background button{
  border: none;
  background-color: #fff76a;
  color: #7abb61;
}
.garden-background .connect-button {
  width: 200px;
  text-align: center;
  margin: 30px auto;
}

.error-collection {
  background-color: red;
  padding: 10px;
  color:black;
  font-weight: 900;
  margin: auto;
  width: 250px;
}