.intro {
  position: relative;
  margin: auto;
  max-width: 800px;
  padding: 30px;
  border-radius: 30px;
  font-size: larger;
  font-weight: 500;
  letter-spacing: .75px;
  background-color: #5f9b55;
}
.intro.mobile {
  border-radius: 0px;
}

.header {
  margin: -50px auto 10px -100px;
  display: flex;
  align-items:center;
  font-weight: 800;
  border-radius: 60px;
  color: #5f9b55;;
  font-size: 18px;
}
.header .mobile{
  margin: 0 auto 10px auto;
  display: flex;
  align-items:center;
  font-weight: 800;
  border-radius: 60px;
  color: #5f9b55;;
  font-size: 18px;
}
.header img{
  position: relative;
  height: 100px;
  z-index: 2;
  animation: pulse 3s infinite ease-in-out, rotate 3s infinite cubic-bezier(0.42,0,0.58,1);
}

@keyframes pulse {
  0% {
    top: 2px;
    transform: rotate(-3deg);
  }
  50% {
    top: -5px;
    transform: rotate(3deg);
  }
  100% {
    top: 2px;
    transform: rotate(-3deg);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(-3deg);
  }
  50% {
    transform: rotate(3deg);
  }
  100% {
    transform: rotate(-3deg);
  }
}
.description {
  padding: 0 10px;
  color: white;

}
.header span {
  position: relative;
  left: -90px;
  top:10px;
  text-align: center; 
  background-color:  #fff76a;
  border-radius: 0px 30px 30px 0px;
  padding: 10px 20px 10px 90px;
  margin: 10px;
}
input {
  border: 2px solid;
  font-size: 16px;
  font-weight: 500;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.mint-wrap {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  border-radius: 10px;
  padding: 30px 10px;
  margin: auto;
}
.connect-button{
  user-select: none;
  font-weight: 800;
  font-size: x-large;
  background-color: #fff76a;
  color: #7abb61;
  border-radius: 20px;
  padding: 10px 20px;
  margin: 30px auto;
  z-index: 1;
}
.connect-button:hover{
  transform: scale(1.1,1.1);
}
.mint-buttons {
  display: flex;
  max-width: 300px;
  background-color: #5f9b55;
  border-radius: 30px;
  padding: 20px 30px;
  margin: 25px auto 10px auto;
}

.minted {
  display: flex;
  flex-direction: column;
  transform: scale(.01);
  background-color: #b4ffa5;
  margin: 10px 5vw;
  border-radius: 20px;
  
}
.mint {
  width: 150px;
  background-color: #b4ffa5;
  border: none;
  border-radius: 30px;
  color: #5f9b55;
  font-size: large;
  font-weight: 900;
  margin-right: 5px;
}
.free-mint {
  width: 200px;
  background-color: #b4ffa5;
  border: none;
  border-radius: 30px;
  color: #5f9b55;
  font-size: large;
  font-weight: 900;
  padding: 10px 0;
}
.amount {
  color: rgba(255, 255, 0, 0.705);
  background: none;
  border: none;
  font-size: large;
  font-weight: 900;
  width: 50px;
  height: 50px;
}
.amount.selected {
  background-color:  #fff76a;
  border-radius: 100px;
  color: #5f9b55;
}
.amount:hover:not(.selected){
  color:  #fff76a;
}
.minted-cards {
  justify-content: space-evenly;
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
  margin: auto;
}
.minted-title {
  font-weight: 800;
  font-size: large;
  margin: 15px auto;
}
.slide-in {
  transition: transform 1s ease-in-out;
  transform: translateY(0) scale(1);
}
.minted-cards img:hover {
  transform: scale(1.04) !important;
  transition: transform .2s ease-in-out;
}
.minted-header {
  margin: auto;
}
.mint-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.minted-title svg {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #5f9b55;;
}
.minted-title svg:hover {
  color: #7abb61;
}
.mint-header .image-wrap {
  margin: auto 0 auto 0px;
  position:relative;
  background-color: #5f9b55;
  padding: 30px;
  border-radius: 20px;
  height: 500px;
}

.mint-header .image-wrap-mobile {
  width: 300px;
}
.image-wrap-mobile img { 
  width: 100%;
}
.image-wrap:before{
  content:'';
  display:block;
  width:0;
  position:absolute;
  left:-80px;
  border-top: 80px solid transparent;
  border-bottom: 80px solid transparent; 
  border-right: 80px solid #5f9b55; 
  
  top:200px;
}
.image-wrap img {
  height: 100%;
  object-fit: contain;
}

.video-wrap {
  height: 600px;
}
video {
  display: block;
  height: 100%;
  object-fit: contain;
  border-radius: 30px;
}
.error-message {
  border-radius: 10px;
  background-color: red;
  padding: 15px 30px;
  font-weight: 800;
  color:black;
  margin: 30px auto 10px auto;
  
}

.mint-stats{
  text-align: center;
  margin: 10px auto;
  vertical-align: middle;
  background-color: #5f9b55;
  color: white;
  font-size: x-large;
  font-weight: 800;
  width: 200px;
  padding:10px 10px 15px 10px;
  border-radius: 20px;
}
.stats-text {
  margin:auto;
}
.mint-stats span {
  font-size: xx-large;
  font-weight: 900;
  margin: 0 5px;

}
.price {
  color:#7abb61;
  background-color: #fff76a;
  margin: -13px auto 10px auto;
  padding: 10px 30px 10px 30px;
  border-radius: 20px;
  font-size: x-large;
  font-weight: 800;
  transform: rotate(3deg);
}