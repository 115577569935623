.loading-text {
  color:white;
  display: block;
  margin:auto;
  text-align: center;
}
.loading-screen { 
  background-color:black !important;
  z-index: 3;
  width: 100%;
  height: 100%;
  position: absolute;
}
.loading-screen img {
  display: block;
  width: 50%;
  margin: auto;
}
.loading-bar{
  width: 60%;
  margin: auto;
}
.loading-bar .border {
  border: 1px solid white;
}
.loading-bar .bar {
  background-color: white;
}