.card {
  transition: all .2s ease-in-out;
  margin: 10px;
  padding: 10px;
  width: 425px;
}
.small {
  width: 225px;
}
.card:hoverdd {
  background-color: rgb(246, 246, 255);
  box-shadow: 8px 7px 0px rgb(104, 104, 104);
}
.card >img {
  max-width: 100%;
  border-radius: 10px;
}
.loading-card {
  display: none;
}
.staked-text {
  font-size: x-large;
  font-weight: 800;
  position: absolute;
  top: 10%;
  left: 50%;
  z-index: 100;
  filter: blur(0px)
}
.staked {
  filter: blur(1px) grayscale(30%);;
}