
.attributes {
  border-radius: 20px;
  background-color: #5f9b55;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 10px 30px;
  justify-content: space-around;
  padding: 15px;
}
.attributes:not(.mobile) {
  border-radius: 20px;
  max-width: 60%;
}

.attribute {
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 10px;
  width: 200px;
  background-color: #45603b;
  color: white;
  display: flex;
  flex-direction: column;
}
.attribute-name {
  color: white;
  font-size: larger;
  font-weight: 800;
  margin-bottom: 10px;
}
.attribute-bar-border {
  width: 100%;
  height: 20px;
  background-color: #7abb61;
  
}
.attribute-bar {
  background-size: 200px 100%;
  filter:brightness(1.2);
  background-image: linear-gradient(to left, #e5405e 0%, #ffdb3a 45%, #3fffa2 100%);
}
.attribute-color-box {
  width: 20px;
  height: 20px;
}

