.table {
  margin: 30px auto;
  max-width: 1000px; 
  display: flex;
  flex-direction: column;
}
.paginator svg {
 background-color: black;
 border-radius: 60px;
 color: white;
}
.paginator svg:hover:not([disabled]) {
  background-color: black;
  border-radius: 60px;
  color: white;
  transform: scale(1.1,1.1);
 }

 .paginator svg:active:not([disabled]) {
  background-color: black;
  border-radius: 60px;
  color: white;
  transform: scale(.9,.9);
 }

 .paginator svg[disabled]{
  display: none;
 }

 table {
  font-size: large;
  font-weight: 700;
  border: 2px solid black;
  box-shadow: 10px 10px 5px #5f9b55;
  border-collapse: collapse;
}
tbody tr:nth-child(odd) {
  background-color: #5f9b55;
}
th, td {
  padding: 15px;

  text-align: left;
}
.paginator {
  margin-top: 10px;
  display: flex;
  width: 100%;
  justify-content: space-between
}
th {
  background-color: black;
  color: white;
  border: none;
}
.connect {
  margin: 20px auto;
  width: 10%;
  display: block;
  background-color: #5f9b55;
  border:none;
  padding: 10px 20px;
  color: white;
  cursor: pointer;
  font-weight: 800;
  border-radius: 30px;
}
.connect:hover {
transform: scale(1.1);
}

td div {
  display: inline-block;
  text-decoration: underline;
  cursor: pointer;
}
td div:hover {
  color: white;
}